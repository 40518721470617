
import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import {authentication} from "@pazion/pazion-core/src/store";
import PazRefreshButton from '@pazion/pazion-core/src/components/controls/PazRefreshButton.vue';
import PazTooltipButton from '@pazion/pazion-core/src/components/controls/PazTooltipButton.vue';
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazAjaxTable from '@pazion/pazion-core/src/components/layout/PazAjaxTable.vue';
import PazTextSearch from "@pazion/pazion-core/src/components/controls/PazTextSearch.vue";
import {
  createNewSimpleDossier,
  deleteDossier,
  getDossierAccount
} from "@pazion/pazion-core/src/services/dossier-service";
import {IDossier} from "@pazion/pazion-core/src/models/dossier.model";

@Component({
  components: {PazTextSearch, PazRefreshButton, PazTooltipButton, PazConfirmation, PazAjaxTable}
})
export default class DossierOverzicht extends Vue {
  $refs!: {
    confirm: PazConfirmation;
  };
  public items: IDossier[] = [];

  public search: string='';

  public headers: object[] = [
    {
      text: 'Naam',
      align: 'right',
      sortable: true,
      value: 'name'
    }, {
      text: 'Propositie',
      align: 'right',
      sortable: true,
      value: 'field_city',
    }, {
      text: 'Passeerdatum',
      align: 'right',
      sortable: true,
      value: 'field_passeerdatum',
    }, {
      text: 'Status',
      align: 'right',
      sortable: true,
      value: 'status',
    }, {
      text: 'Acties',
      align: 'right',
      sortable: true,
      value: 'acties',
    },
  ];
  public loading: boolean = true;

  get isAdmin() {
    return authentication.isAdmin;
  }

  mounted() {
    this.fetchData();
  }

  editLink(id: any) {
    this.$router.push({name: 'dossier-view', params: {id}});
  }

  displayStatus(item: IDossierItem) {
    return item.status;
  }

  async confirmDeletion(item: any) {

    const confirm = await this.$refs.confirm.openConfirmDialog(
        "Delete",
        "Are you sure you want to delete `" + item.name + "`?"
    );
    if (confirm) {
      const response = await deleteDossier(item.id);
      if (response) await this.fetchData();
    }
  }

  async newDossierAction() {
    let rsp = await createNewSimpleDossier();
    if (rsp.id) {
      await this.$router.push({name: 'dossier-view', params: {id: '' + rsp.id}});
    }
  }

  async fetchData() {
    this.loading = true;
    this.items = await getDossierAccount();
    if (this.items.length == 1 && !authentication.isAdmin) {
      await this.$router.push({name: 'dossier-view', params: {id: '' + this.items[0]['id']}});
    }
    this.loading = false;
  }

}
