import '@pazion/pazion-core/src/config';
import {addRoute} from '@pazion/pazion-core/src/router';
import DossierOverzicht from './views/dossier/DossierOverzicht.vue';
import {removeRouteByName} from '@pazion/pazion-core/src/router';
import Dashboard from '@pazion/pazion-core/src/views/Dashboard.vue';
import DossierViewWithRequiredCategories from '@pazion/pazion-core/src/views/dossier/DossierViewWithRequiredCategories.vue';

addRoute({
  path: '/',
  name: 'Home',
  component: Dashboard,
  meta: {
	'show-in-nav': false,
	roles: ['user'],
	sort: -100
  },
});


removeRouteByName('test');
// removeRouteByName('queue');
// removeRouteByName('logs');
// removeRouteByName('settings');
removeRouteByName('dossier');
removeRouteByName('dossier-course-page');
addRoute({
  path: '/',
  name: 'Home',
  component: DossierOverzicht,
});

addRoute({
  path: '/dossier/:id',
  name: 'dossier-view',
  component: DossierViewWithRequiredCategories,
});

