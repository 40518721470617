import Vue from 'vue';
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
} from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import {Ripple, Intersect, Touch, Resize} from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  components: {VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect},
  directives: {Ripple, Intersect, Touch, Resize},
});

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#ad974f',
                 secondary: '#232323',
                accent: '#232323',
                /*
                error: '#D32F2F',*/
            },
        },
    },
});
